import {useContext} from 'react';
import PropTypes from 'prop-types';
import FeedbackItem from './FeedbackItem';
import FeedbackContext from '../context/FeedbackContext';

function FeedbackList() {
	const {feedback} = useContext(FeedbackContext);

	if (!feedback || !feedback.length) {
		return <p>No Feedback Yet</p>;
	}
	const list = feedback.map((item) => 
		(
		<FeedbackItem key={item.id} item={item} />
		)
	);
	//return (<div className='feedback-list'>{list}</div>);
	return (
	<div className='ffedback-list'>
        {list}
	</div>
	);

    /* with animation
    import {motion, AnimatePresence} from 'framer-motion';
    const list = feedback.map((item) => 
		(
		<motion.div key={item.id}
			initial={{opacity: 0}}
			animate={{opacity:1}}
			exit={{opacity:0}}
		>
			<FeedbackItem key={item.id} item={item} 
				handleDelete={handleDelete} />
		</motion.div>
		)
	);
	//return (<div className='feedback-list'>{list}</div>);
	return (
	<div className='ffedback-list'>
	<AnimatePresence>
		{list}
	</AnimatePresence>
	</div>
    */
}

FeedbackList.proprTypes = {
	feedback: PropTypes.arrayOf(
		PropTypes.shape({
		id:     PropTypes.number.isRequired,
		text:   PropTypes.string.isRequired,
		rating: PropTypes.number.isRequired,
	})
),
}

export default FeedbackList;
