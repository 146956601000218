import {Link} from 'react-router-dom';
import {FaPlus} from 'react-icons/fa';

function PostIconLink() {
	return (
	<div className='post-link'>
		<Link to='post'>
			<FaPlus size={30} />
		</Link>
	</div>
	);
}

export default PostIconLink;
