import {Link} from 'react-router-dom';
import Card from '../components/shared/Card';

function AboutPage() {
	return ( 
	<Card>
		<h1>About</h1>
		<p>React app ... </p>
		<p>Version: 1.0.0</p>
		<p>
		<Link to='/'>Back to Home</Link>
		</p>
	</Card>
	);
}

export default AboutPage;
