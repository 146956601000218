import {useContext, useState, useEffect} from 'react';
import FeedbackContext from '../context/FeedbackContext';
import RatingSelect from './RatingSelect';
import Card from './shared/Card';
import Button from './shared/Button';

function FeedbackForm() {
	const {feedbackEdit, updateFeedback, addFeedback} = 
		useContext(FeedbackContext);

	const [text, setText] = useState('');
	const [rating, setRating] = useState(10);
	const [btnDisabled, setBtnDisabled] = useState(true);
	const [message, setMessage] = useState(null);

	useEffect(() => {
		if (feedbackEdit.edit) {
			setBtnDisabled(true);
			setText(feedbackEdit.item.text);
			setRating(feedbackEdit.item.rating);
		}
	}, [feedbackEdit]);

	const handleRating = (r) => {
		setRating(r);
		if (feedbackEdit.edit) {
			if (text.length < 10) {
				setBtnDisabled(true);
			}
			else {
				setBtnDisabled(false);
			}
		}
	}
	
	const handleTextChange = (e) => {
		const txt = e.target.value;
		setText(txt);
		if (txt.length < 10) {
			setBtnDisabled(true);
			setMessage('Text must be at least 10 characters');
		}
		else {
			setBtnDisabled(false);
			setMessage(null);
		}
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		if (text.trim().length > 10) {
			if (feedbackEdit.edit) {
				const newFeedback = {
					id: feedbackEdit.item.id, 
					text, rating};
				updateFeedback(newFeedback);
			}
			else {
				const newFeedback = {text, rating};
				addFeedback(newFeedback);
			}
			setText('');
		}
	}

	return (
	<Card>
	<form onSubmit={handleSubmit}>
		<h2>How would you rate your service with us?</h2>
		<RatingSelect select={(r) => handleRating(r)}/>
		<div className='input-group'>
			<input type='text' placeholder='Write a review' 
				value={text} onChange={handleTextChange} />
			<Button type='submit' version='primary' 
				isDisabled={btnDisabled}>Send</Button>
		</div>
		{message && <div className='message'>{message}</div>} 
	</form>
	</Card>
	);
}

export default FeedbackForm;
