import {BrowserRouter as Router, Routes, Route}  
	from 'react-router-dom';
import Header from './components/Header';
import FeedbackList from './components/FeedbackList';
import FeedbackStats from './components/FeedbackStats';
import FeedbackForm from './components/FeedbackForm';
import PropTypes from 'prop-types';
import {FeedbackProvider} from './context/FeedbackContext';
import AboutPage from './routes/AboutPage';
import AboutIconLink from './components/AboutIconLink';
import PostIconLink from './components/PostIconLink';
import Post from './components/Post';

function App() {
	return (
	<FeedbackProvider>
	<Router>
	<Header />
	<div className="container">
		<Routes>		
		<Route path='/' element={
		<>
			<FeedbackForm />
			<FeedbackStats />
			<FeedbackList />
		</>} />

		<Route path='/about' element={<AboutPage />} />
		<Route path='/post/*' element={<Post />} />
		</Routes>
	
		<AboutIconLink />
		<PostIconLink />
	</div>
	</Router>
	</FeedbackProvider>
	);
}

Header.defaultProps = {
	text: 'Feedback UI',
	bgColor: 'rgba(0,0,0,0.4)',
	textColor: '#ff6a95',
}

Header.propTypes = {
	text:      PropTypes.string,
	bgColor:   PropTypes.string,
	textColor: PropTypes.string,
}

export default App;

