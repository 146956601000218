import {useContext} from 'react';
import FeedbackContext from '../context/FeedbackContext';

function FeedbackStats() {
	const {feedback} = useContext(FeedbackContext);	

	var avg = 0;
	if (feedback.length > 0) {
		avg = feedback.reduce((acc,cur) => (acc+cur.rating), 0) / feedback.length;
		avg = avg.toFixed(1);
	}
	return (
	<div className="feedback-stats">
		<h4>{feedback.length} Reviews</h4>
		<h4>Average Rating: {avg}</h4>
	</div>
	);
}

export default FeedbackStats;

