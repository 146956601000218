import {createContext, useState} from 'react';
import {v4 as uuidv4} from 'uuid';

const FeedbackContext = createContext();

export const FeedbackProvider = ({children}) => {
	const [feedback, setFeedback] = 
		useState([
		{id: 1, text: 'This is item 1', rating: 10}, 
		{id: 2, text: 'This is item2', rating: 4}, 
		{id: 3, text: 'This is item 3', rating: 7}, 
		]);

	const [feedbackEdit, setFeedbackEdit] = useState({
		item: {}, 
		edit: false
	});

	const editFeedback = (item) => {
		setFeedbackEdit({item, edit: true});
	}

	const updateFeedback = (item) => {
		setFeedback(feedback.map((d) => {
			if (d.id !== item.id) {
				return d;
			}
			return (item);
		}));
		setFeedbackEdit({item: {}, edit: false});
	}

	const addFeedback = (item) => {
		item.id = uuidv4();
		setFeedback([item, ...feedback]);
	}

	const deleteFeedback = (id) => {
		if(window.confirm(`Are you sure you want to delete ${id}?`)) {
			setFeedback(feedback.filter((d) => (d.id!==id)));
		}
	}

	return (
		<FeedbackContext.Provider value={
		{feedback, feedbackEdit, editFeedback, updateFeedback, 
		addFeedback, deleteFeedback}} >
			{children}
		</FeedbackContext.Provider>
	);
}

export default FeedbackContext;
