import {useContext} from 'react';
import PropTypes from 'prop-types';
import {FaTimes, FaEdit} from 'react-icons/fa';
import Card from './shared/Card';
import FeedbackContext from '../context/FeedbackContext';

function FeedbackItem({item}) {
	const {deleteFeedback, editFeedback} = useContext(FeedbackContext);
	const {id, rating, text} = item;
	// array destructuring
	//const [rating, setRating] = useState(7);
	//const [text, setText] = useState('Feedback item');
	return (
	<Card reverse={false}>
		<button onClick={()=>deleteFeedback(id)} className="close">
			<FaTimes color='purple'/>
		</button>
		<button onClick={() => editFeedback(item)} className="edit">
			<FaEdit color='purple'/>
		</button>
		<div className="num-display">{rating}</div>
		<div className="text-display">{text}</div>
	</Card>
	);
}

FeedbackItem.defaultProps = {
	rating: 0,
	text: '',
}

FeedbackItem.propTypes = {
	rating: PropTypes.number.isRequired,
	text: PropTypes.string,
}

export default FeedbackItem;
